import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ClipLoader from 'react-spinners/ClipLoader'

const useStyles = makeStyles((theme) => ({
  fullScreenBackground: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
  },
}))
const Loader = ({ loading, backgroundHeight }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.fullScreenBackground}
      style={{ height: backgroundHeight }}
    >
      <ClipLoader
        loading={loading}
        cssOverride={{ borderWidth: 4 }}
        size={50}
        color="#AEB063"
      />
    </div>
  )
}

Loader.defaultProps = {
  backgroundHeight: '100vh'
}

export default Loader
