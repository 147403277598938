import React, { lazy, Suspense } from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Box } from '@material-ui/core'
import Loader from '../components/Loader'
import Impersonate from '../pages/Home/Impersonate'
import OptOutUser from '../pages/unsubscribe/OptOutUser'
import Unsubscribe from '../pages/unsubscribe/UnsubscribeMailer'
const Navbar = lazy(() => import('../components/navbar'))
const ResourcesNavbar = lazy(() => import('../components/resourcesNavbar'))
const Copyright = lazy(() => import('../components/copyright'))
const ResearcherNavbar = lazy(() => import('../components/researcherNavbar'))
const ConsumerNavbar = lazy(() => import('../components/consumerNavbar'))

const RedirectPage = lazy(() =>
  import('../pages/researcher/RedirectModalPage/RedirectPage')
)
const RedirectView = lazy(() => import('../pages/Redirect'))
const Home = lazy(() => import('../pages/Home'))
const Contact = lazy(() => import('../pages/Home/Contact'))
const TermsAndConditions = lazy(() =>
  import('../pages/Home/TermsAndConditions')
)
const PrivacyPolicy = lazy(() => import('../pages/Home/PrivacyPolicy'))
const DataPolicy = lazy(() => import('../pages/Home/DataProtectionPolicy'))

const FrequentlyAskedQuestions = lazy(() =>
  import('../pages/Home/FrequentlyAskedQuestions')
)
const RespondentFaq = lazy(() => import('../pages/Home/RespondentFaq'))
const RespondentTos = lazy(() =>
  import('../pages/responder/StudyAnswer/RespondentTos')
)
const Login = lazy(() => import('../pages/Home/Login'))
const Signup = lazy(() => import('../pages/Home/Signup'))
const ForgotPassword = lazy(() => import('../pages/Home/ForgotPassword'))
const Resources = lazy(() => import('../pages/Home/Resources/Resources'))
const CreateSurvey = lazy(() => import('../pages/Home/CreateSurvey'))
const Health = lazy(() => import('../pages/Home/Health'))

const CaseStudies = lazy(() => import('../pages/Home/Resources/CaseStudies'))
const Inspiration = lazy(() => import('../pages/Home/Resources/Inspiration'))
const ProTips = lazy(() => import('../pages/Home/Resources/ProTips'))
const UseCases = lazy(() => import('../pages/Home/Resources/UseCases'))

const SurveyOptions = lazy(() => import('../pages/Home/SurveyOptions'))
const Verify = lazy(() => import('../pages/Home/Verify'))
const ResetPassword = lazy(() => import('../pages/Home/ResetPassword'))
const VerifyShareStudy = lazy(() => import('../pages/Home/VerifyShareStudy'))
const FullService = lazy(() => import('../pages/Home/FullService'))
const CommunitySearch = lazy(() =>
  import('../pages/researcher/Community/Search')
)

const VerifyInvitedStudy = lazy(() =>
  import('../pages/Home/VerifyInvitedStudy')
)
const ConsumerLogin = lazy(() => import('../pages/Home/ConsumerLogin'))

const ParticipateInStudy = lazy(() =>
  import('../pages/Home/ParticipateInStudy')
)

const ConsumerDashboard = lazy(() => import('../pages/responder/Dashboard'))
const ConsumerProfile = lazy(() => import('../pages/responder/Profile'))
const StudyAnswer = lazy(() => import('../pages/responder/StudyAnswer'))

const VideoDashboard = lazy(() => import('../pages/researcher/VideoDashboard'))
const QuotesDashboard = lazy(() =>
  import('../pages/researcher/QuotesDashboard')
)
const StudyBuilder = lazy(() => import('../pages/researcher/StudyBuilder'))
const ResearcherProfile = lazy(() => import('../pages/researcher/Profile'))
const UserCredits = lazy(() => import('../pages/credits'))
const PaymentReceipts = lazy(() => import('../pages/payments'))

const CompleteProfile = lazy(() => import('../pages/Home/CompleteProfile'))
const TapestryPortal = lazy(() => import('../pages/researcher/TapestryPortal'))

const ResearcherDashboard = lazy(() => import('../pages/researcher/Dashboard'))
const CatchallPage = lazy(() => import('./CatchallPage'))

const SAPView = lazy(() =>
  import('../pages/researcher/StudyApplicationProgress')
)

const ResearcherPrivateRoute = ({ ...rest }) => {
  const [cookies] = useCookies(['access_token'])
  return cookies.access_token ? (
    <Route {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: rest.location },
      }}
    />
  )
}
const ConsumerPrivateRoute = ({ ...rest }) => {
  const [cookies] = useCookies(['user_data_consumer'])
  return cookies.user_data_consumer ? (
    <Route {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: '/consumer',
        state: { from: rest.location },
      }}
    />
  )
}
const routesView = () => {
  const routes = [
    {
      path: '/',
      exact: true,
      navbar: () => <Navbar active="Home" />,
      copyright: () => <Copyright />,
      main: () => (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      ),
    },
    {
      path: '/contact',
      navbar: () => <Navbar active="Contact" />,
      copyright: () => <Copyright />,
      main: () => <Contact />,
    },
    {
      path: '/login',
      navbar: () => <Navbar active="Login" />,
      copyright: () => <Copyright />,
      main: (props) => <Login {...props} />,
    },
    {
      path: '/create_survey',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <CreateSurvey {...props} />,
    },
    {
      path: '/signup',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <Signup {...props} />,
    },
    {
      path: '/forgot_password',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <ForgotPassword />,
    },

    {
      path: '/resources',
      navbar: () => <Navbar active="Resources" />,
      copyright: () => <Copyright />,
      main: ({ subRoutes }) => (
        <>
          <Switch>
            {subRoutes.map((subRoute, index) => (
              // You can render a <Route> in as many places
              // as you want in your app. It will render along
              // with any other <Route>s that also match the URL.
              // So, a Navbar or anything else
              // that requires you to render multiple things
              // in multiple places at the same URL is nothing
              // more than multiple <Route>s.
              <Route
                key={index}
                path={subRoute.path}
                exact={subRoute.exact}
                children={(props) => <subRoute.navbar {...props} />}
              />
            ))}
          </Switch>
          <Switch>
            {subRoutes.map((subRoute, index) => (
              <Route
                key={index}
                path={subRoute.path}
                exact={subRoute.exact}
                children={(props) => (
                  <subRoute.main subRoutes={subRoute.routes} {...props} />
                )}
              />
            ))}
          </Switch>
        </>
      ),
      routes: [
        {
          path: '/resources',
          exact: true,
          navbar: () => null,
          main: (props) => <Resources {...props} />,
        },
        {
          path: '/resources/case-studies/:id?',
          navbar: () => <ResourcesNavbar active="Case Studies" />,
          main: (props) => <CaseStudies {...props} />,
        },
        {
          path: '/resources/inspiration/:id?',
          navbar: () => <ResourcesNavbar active="Inspiration" />,
          main: (props) => <Inspiration {...props} />,
        },
        {
          path: '/resources/pro-tips/:id?',
          navbar: () => <ResourcesNavbar active="Pro Tips" />,
          main: (props) => <ProTips {...props} />,
        },
        {
          path: '/resources/use-cases/:id?',
          navbar: () => <ResourcesNavbar active="Use Cases" />,
          main: (props) => <UseCases {...props} />,
        },
      ],
    },

    {
      path: '/survey_options',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <SurveyOptions />,
    },
    {
      path: '/full_service',
      navbar: () => <Navbar active="Services" />,
      copyright: () => <Copyright />,
      main: () => <FullService />,
    },
    {
      path: '/health',
      navbar: () => <></>,
      copyright: () => <></>,
      main: ({ location }) => <Health location={location} />,
    },
    {
      path: '/verify',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <Verify />,
    },
    {
      path: '/impersonate',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <Impersonate {...props} />,
    },
    {
      path: '/opt_out_user',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <OptOutUser {...props} />,
    },
    {
      path: '/unsubscribe',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <Unsubscribe {...props} />,
    },
    {
      path: '/reset_password',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <ResetPassword {...props} />,
    },
    {
      path: '/verify_share_study',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <VerifyShareStudy {...props} />,
    },
    {
      path: '/verify_invited_study',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: (props) => <VerifyInvitedStudy {...props} />,
    },
    {
      path: '/participate_in_study',
      navbar: () => <Navbar />,
      main: (props) => <ParticipateInStudy {...props} />,
      copyright: () => <Copyright />,
    },
    {
      path: '/user_terms',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <TermsAndConditions />,
    },
    {
      path: '/privacy_policy',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <PrivacyPolicy />,
    },
    {
      path: '/data-protection-policy',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <DataPolicy />,
    },
    {
      path: '/faq',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <FrequentlyAskedQuestions />,
    },
    {
      path: '/respondent_tos',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <RespondentTos />,
    },
    {
      path: '/respondent_faq',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: () => <RespondentFaq />,
    },
    {
      path: '/share/:id',
      navbar: () => <ResearcherNavbar active="Share" />,
      copyright: () => <Copyright loggedIn={true} />,
      main: (props) => <VideoDashboard {...props} />,
    },
    {
      path: '/s/:id',
      navbar: () => <ResearcherNavbar active="Share" />,
      copyright: () => <Copyright loggedIn={true} />,
      main: (props) => <RedirectView redirectParam={'s'} {...props} />,
    },
    {
      path: '/f/:id',
      navbar: () => <ResearcherNavbar active="Share" />,
      copyright: () => <Copyright loggedIn={true} />,
      main: (props) => <RedirectView redirectParam={'f'} {...props} />,
    },
    {
      path: '/researcher',
      navbar: () => <ResearcherNavbar />,
      copyright: () => <Copyright loggedIn={true} />,
      main: ({ subRoutes }) => (
        <Switch>
          {subRoutes.map((subRoute, index) => (
            <ResearcherPrivateRoute
              key={index}
              path={subRoute.path}
              exact={subRoute.exact}
              children={(props) => (
                <subRoute.main subRoutes={subRoute.routes} {...props} />
              )}
            />
          ))}
        </Switch>
      ),
      routes: [
        {
          path: '/researcher',
          exact: true,
          main: () => <ResearcherDashboard />,
        },
        {
          path: '/researcher/complete_profile',
          main: (props) => <CompleteProfile {...props} />,
        },
        {
          path: '/researcher/dashboard',
          main: () => <ResearcherDashboard />,
        },
        {
          path: '/researcher/SAP/:id',
          main: (props) => <SAPView {...props} />,
        },
        {
          path: '/researcher/profile',
          main: () => <ResearcherProfile />,
        },
        {
          path: '/researcher/community/search',
          main: () => <CommunitySearch />,
        },
        {
          path: '/researcher/credits',
          main: () => <UserCredits />,
        },
        {
          path: '/researcher/payment-receipts',
          main: () => <PaymentReceipts />,
        },
        {
          path: '/researcher/fabric_ai_portal',
          main: (props) => <TapestryPortal {...props} />,
        },
        {
          path: '/researcher/studybuild/:id?',
          main: (props) => <StudyBuilder {...props} />,
        },
        {
          path: '/researcher/study/:id/quotes',
          main: (props) => <QuotesDashboard {...props} />,
        },
        {
          path: '/researcher/study/:id',
          main: (props) => <VideoDashboard {...props} />,
        },
        {
          path: '/researcher/redirect',
          main: (props) => <RedirectPage {...props} />,
        },
        {
          path: '*',
          navbar: () => <Navbar />,
          copyright: () => <Copyright />,
          main: ({ location }) => <CatchallPage key={location.key} />,
        },
      ],
    },
    {
      path: '/consumer',
      navbar: () => <ConsumerNavbar />,
      copyright: () => <Copyright loggedIn={true} />,
      main: ({ subRoutes }) => (
        <Switch>
          {subRoutes.map((subRoute, index) => {
            if (subRoute.path === '/consumer') {
              return (
                <Route
                  key={index}
                  path={subRoute.path}
                  exact={subRoute.exact}
                  children={(props) => (
                    <subRoute.main subRoutes={subRoute.routes} {...props} />
                  )}
                />
              )
            }
            return (
              <ConsumerPrivateRoute
                key={index}
                path={subRoute.path}
                exact={subRoute.exact}
                children={(props) => (
                  <subRoute.main subRoutes={subRoute.routes} {...props} />
                )}
              />
            )
          })}
        </Switch>
      ),
      routes: [
        {
          path: '/consumer',
          exact: true,
          main: (props) => <ConsumerLogin {...props} />,
        },
        {
          path: '/consumer/dashboard',
          main: () => <ConsumerDashboard />,
        },
        {
          path: '/consumer/profile',
          main: (props) => <ConsumerProfile {...props}/>,
        },
        {
          path: '/consumer/study_answer/:id?',
          main: (props) => <StudyAnswer {...props} />,
        },
      ],
    },
    {
      path: '*',
      navbar: () => <Navbar />,
      copyright: () => <Copyright />,
      main: ({ location }) => <CatchallPage key={location.key} />,
    },
  ]

  return (
    <Suspense
      fallback={
        <Box display="flex" flexDirection="column" style={{ flex: 1 }}>
          <Loader backgroundHeight="100vh" />
        </Box>
      }
    >
      <Switch>
        {routes.map((route, index) => (
          // You can render a <Route> in as many places
          // as you want in your app. It will render along
          // with any other <Route>s that also match the URL.
          // So, a Navbar or anything else
          // that requires you to render multiple things
          // in multiple places at the same URL is nothing
          // more than multiple <Route>s.
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={(props) => <route.navbar {...props} />}
          />
        ))}
      </Switch>
      <Switch>
        {routes.map((route, index) => (
          // Render more <Route>s with the same paths as
          // above, but different components this time.
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={(props) => (
              <route.main subRoutes={route.routes} {...props} />
            )}
          />
        ))}
      </Switch>
      <Switch>
        {routes.map((route, index) => (
          // You can render a <Route> in as many places
          // as you want in your app. It will render along
          // with any other <Route>s that also match the URL.
          // So, a Navbar or anything else
          // that requires you to render multiple things
          // in multiple places at the same URL is nothing
          // more than multiple <Route>s.
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={(props) => <route.copyright {...props} />}
          />
        ))}
      </Switch>
    </Suspense>
  )
}

export default routesView
