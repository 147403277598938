import React from 'react'
import ReactDOM from 'react-dom'
import WebFont from 'webfontloader'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import theme from './config/theme'
import { CookiesProvider } from 'react-cookie'
import App from './pages/App'

// Loading the web font
WebFont.load({
  google: {
    families: ['Source Sans Pro:300,400,700&display=swap']
  },
})

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
