import packageJson from '../package.json'
import React from 'react'

global.appVersion = packageJson.version

const checkVersionMismatch = (letestVersion, currentVersion) => {
  const latestVersion = letestVersion.split(/\./g)
  const currentNewVersion = currentVersion.split(/\./g)

  while (latestVersion.length || currentNewVersion.length) {
    const a = Number(latestVersion.shift())
    const b = Number(currentNewVersion.shift()) // eslint-disable-next-line no-continue

    if (a === b) continue // eslint-disable-next-line no-restricted-globals

    return a > b || isNaN(b)
  }

  return false
}

class CacheHandler extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,

      isLatestVersionAvailable: false,

      clearCacheAndReload: () => {
        if (caches) {
          // deleting saved cache one by one

          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name)
          })
        } // after deleting cached data hard reload the site

        // window.location.reload(true)
      },
    }
  }
  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())

      .then((meta) => {
        const latestVersion = meta.version

        const currentVersion = global.appVersion
        const shouldForceRefresh = checkVersionMismatch(
          latestVersion,
          currentVersion
        )

        if (shouldForceRefresh) {
          this.setState({ isLoading: false, isLatestVersion: false })
        } else {
            this.setState({ isLoading: false })
        }
      })
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Rendering the component only if
    // passed props value is changed

    if (nextState.isLoading !== this.state.isLoading) {
      return true
    } else {
      return false
    }
  }

  render() {
    const {
      isLoading,
      isLatestVersionAvailable,
      clearCacheAndReload,
    } = this.state

    return this.props.children({
      isLoading,
      isLatestVersionAvailable,
      clearCacheAndReload,
    })
  }
}

export default CacheHandler
