import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#AEB063' //Dark Khaki
    },
    secondary: {
      main: '#f29e29' //Dark orange
    },
    success: {
      main: '#2ecc71'
    },
    error: {
      main: '#e84118'
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Source Sans Pro'
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: 0,
        '@media (min-width: 0px)': {
          minWidth: 0
        }
      }
    },
    MUIRichTextEditor: {
      root: {
    
        width: '100%',
      },
      container: {
        display: 'flex',
        flexDirection: 'column-reverse'
      },
      editor: {  
       
      },
      toolbar: {
        borderTop: '1px solid gray',
        // Add styles here for toolbar of RTE
      },
      placeHolder: {
      
        paddingLeft: 0,
      }
    },
  }
})

export default theme
