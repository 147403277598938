import React, { useEffect } from 'react'
import Routes from '../../config/routes'
import { Helmet } from 'react-helmet'
import CacheHandler from '../../CacheHandler'
import { LanguageProvider } from '../../hooks/languageContext'

const MainApp = () => {
  return (
    <>
      <LanguageProvider>
        <Helmet>
          <title>Fabric</title>
          <meta
            name="description"
            content="A full-service, digital market research firm with global reach. Bring authentic consumer voices and faces into your development process via mobile video. See for yourself."
          />
        </Helmet>
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              flex: 1,
              background: '#FCFCF4',
            }}
          >
            <Routes />
          </div>
        </div>
      </LanguageProvider>
    </>
  )
}

const App = () => {
  return (
    <CacheHandler>
      {({ isLoading, isLatestVersionAvailable, clearCacheAndReload }) => {
        if (isLoading) return null

        if (!isLoading && !isLatestVersionAvailable) {
          // You can decide how and when you want to force reload

          clearCacheAndReload()
        }
        return <MainApp />
      }}
    </CacheHandler>
  )
}

export default App
