import React, { memo, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import { ReactComponent as NotFoundIcon } from '../../assets/icons/checkGreen.svg'
import { ReactComponent as SorryIcon } from '../../assets/icons/seeYouLeave.svg'

import { useLocation } from 'react-router-dom'
import { API } from '../../utils'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const OptOutUser = (props) => {
  let query = useQuery()

  const [apiData, setApiData] = useState(null)

  const hitUnsubApi = async () => {
    const userId = query.get('user_id')
    const communityId = query.get('community_id')
    let formdata = new FormData()
    formdata.append('user_id', userId)
    formdata.append('community_id', communityId)
    if (userId && communityId) {
      const data = await API({
        method: 'PATCH',
        url: '/community/opt_out_user',
        body: formdata,
      })

      if (data) {
        setApiData(data.meta)
      }
    } else {
      console.warn('No ids found')
    }
  }

  const handleUnsubscribe = () => {
    hitUnsubApi()
  }

  if (apiData) {
    return (
      <Box
        minHeight="100vh"
        width="100%"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
      >
        <Box pt={5}>
          <Box
            pt={5}
            style={{
              transform: 'scale(0.6)',
              maxHeight: '300px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {apiData.ok ? (
              <NotFoundIcon height={'300px'} />
            ) : (
              <SorryIcon height={'300px'} />
            )}
          </Box>
          <Typography
            variant="h5"
            style={{
              color: '#F39F29',
              textAlign: 'center',
            }}
          >
            {apiData.ok
              ? 'You have successfully unsubscribed from this community'
              : apiData.message}
          </Typography>
          <Box
            py={2}
            pt={4}
            display="flex"
            justifyContent={'center'}
            alignItems="center"
          >
            <Button variant="outlined" style={{ textTransform: 'none' }} href="/">
              Go to Homepage
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      minHeight="100vh"
      width="100%"
      justifyContent="center"
      alignItems="flex-start"
      display="flex"
    >
      <Box pt={5}>
        <Box
          pt={5}
          style={{
            transform: 'scale(0.6)',
            maxHeight: '300px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <SorryIcon height={'300px'} />
        </Box>
        <Typography
          variant="h5"
          style={{
            color: '#F39F29',
            textAlign: 'center',
          }}
        >
          Do you wish to unsubscibe from this community,
          <br /> and not receive any further any emails related to this.
        </Typography>
        <Box
          py={2}
          pt={4}
          display="flex"
          justifyContent={'center'}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUnsubscribe}
            style={{ textTransform: 'none', marginRight: '12px' }}
          >
            Yes unsubscibe
          </Button>

          <Button variant="outlined" style={{ textTransform: 'none' }}  href="/">
            No, keep me subscribed
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(OptOutUser)
