import React, { memo, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import { ReactComponent as NotFoundIcon } from '../../assets/icons/checkGreen.svg'
import { ReactComponent as SorryIcon } from '../../assets/icons/seeYouLeave.svg'

import { useLocation } from 'react-router-dom'
import { API } from '../../utils'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const UnsubscribeMailer = (props) => {
  let query = useQuery()

  const [apiData, setApiData] = useState(null)
  const [getApiData, setGetApiData] = useState(null)
  const isUserElligibleForUnsub = async () => {
    const userId = query.get('user_id')
    const source = query.get('source')
    if (userId && source) {
      const data = await API({
        method: 'GET',
        url: `mailer_unsubscribe?source=${source}&user_id=${userId}`,
      })
      if (data) {
        setGetApiData(data.meta)
        console.log(data)
      }
    } else {
      console.warn('No ids found')
    }
  }

  useEffect(() => {
    isUserElligibleForUnsub()
  }, [])

  const hitUnsubApi = async () => {
    const userId = query.get('user_id')
    const source = query.get('source')
    // let formdata = new FormData()
    // formdata.append('user_id', userId)
    // formdata.append('source', communityId)
    if (userId && source) {
      const data = await API({
        method: 'PATCH',
        url: `mailer_unsubscribe?source=${source}&user_id=${userId}`,
      })
      if (data) {
        setApiData(data.meta)
      }
    } else {
      console.warn('No ids found')
    }
  }

  const handleUnsubscribe = () => {
    hitUnsubApi()
  }

  if (apiData) {
    return (
      <Box
        minHeight="100vh"
        width="100%"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
      >
        <Box pt={5}>
          <Box
            pt={5}
            style={{
              transform: 'scale(0.6)',
              maxHeight: '300px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {apiData.ok ? (
              <NotFoundIcon height={'300px'} />
            ) : (
              <SorryIcon height={'300px'} />
            )}
          </Box>
          <Typography
            variant="h5"
            style={{
              color: '#F39F29',
              textAlign: 'center',
            }}
          >
            {apiData.ok
              ? 'You have successfully unsubscribed from this community'
              : apiData.message}
          </Typography>
          <Box
            py={2}
            pt={4}
            display="flex"
            justifyContent={'center'}
            alignItems="center"
          >
            <Button
              variant="outlined"
              style={{ textTransform: 'none' }}
              href="/"
            >
              Go to Homepage
            </Button>
          </Box>
        </Box>
      </Box>
    )
  } else if (getApiData && getApiData.ok === false) {
    return (
      <Box
        minHeight="100vh"
        width="100%"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
      >
        <Box pt={5}>
          <Box
            pt={5}
            style={{
              transform: 'scale(0.6)',
              maxHeight: '300px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <SorryIcon height={'300px'} />
          </Box>
          <Typography
            variant="h5"
            style={{
              color: '#F39F29',
              textAlign: 'center',
            }}
          >
            Sorry to see you go, <br/> Please confirm if you want to unsubscribe from
            all mail from fabric.is
          </Typography>
          <Box
            py={2}
            pt={4}
            display="flex"
            justifyContent={'center'}
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUnsubscribe}
              style={{ textTransform: 'none', marginRight: '12px' }}
            >
              Yes unsubscibe me
            </Button>

            <Button
              variant="outlined"
              style={{ textTransform: 'none' }}
              href="/"
            >
              No, keep me subscribed
            </Button>
          </Box>
        </Box>
      </Box>
    )
  } else if (getApiData && getApiData.ok === true) {
    return (
      <Box
        minHeight="100vh"
        width="100%"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
      >
        <Box pt={5}>
          <Box
            pt={5}
            style={{
              transform: 'scale(0.6)',
              maxHeight: '300px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {getApiData.ok ? (
              <NotFoundIcon height={'300px'} />
            ) : (
              <SorryIcon height={'300px'} />
            )}
          </Box>
          <Typography
            variant="h5"
            style={{
              color: '#F39F29',
              textAlign: 'center',
            }}
          >
            {getApiData.ok
              ? 'You have successfully unsubscribed from this community'
              : getApiData.message}
          </Typography>
          <Box
            py={2}
            pt={4}
            display="flex"
            justifyContent={'center'}
            alignItems="center"
          >
            <Button
              variant="outlined"
              style={{ textTransform: 'none' }}
              href="/"
            >
              Go to Homepage
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }
  return (
    <Box
      minHeight="100vh"
      width="100%"
      justifyContent="center"
      alignItems="flex-start"
      display="flex"
    >
      <Box pt={5}>
        <Box
          pt={5}
          style={{
            transform: 'scale(0.6)',
            maxHeight: '300px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <SorryIcon height={'300px'} />
        </Box>
        <Typography
          variant="h5"
          style={{
            color: '#F39F29',
            textAlign: 'center',
          }}
        >
          Sorry to see you go, <br/> Please confirm if you want to unsubscribe from
          all mail from fabric.is
        </Typography>
        <Box
          py={2}
          pt={4}
          display="flex"
          justifyContent={'center'}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUnsubscribe}
            style={{ textTransform: 'none', marginRight: '12px' }}
          >
            Yes unsubscibe
          </Button>

          <Button variant="outlined" style={{ textTransform: 'none' }} href="/">
            No, keep me subscribed
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(UnsubscribeMailer)