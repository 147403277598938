import React, { memo, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import { API } from '../../utils'

import CustomSnackBar from '../../components/CustomSnackBar'
import { useCookies } from 'react-cookie'

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: 0,
    fontWeight: 700,
    fontSize: '60px',
  },
}))

const Impersonate = ({ history }) => {
  const classes = useStyles()
  const [, setCookie, removeCookie] = useCookies([
    'remember_cookie',
    'user_data',
    'access_token',
  ])
  const [snackbar, setSnackbar] = useState({})
  const [apiData, setApiData] = useState()

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    let TOKEN = null,
      ROLE = null,
      tmp = []
    window.location.search
      .substr(1)
      .split('&')
      .forEach(function (item) {
        tmp = item.split('=')
        if (tmp[0] === 'token') TOKEN = decodeURIComponent(tmp[1])
        if (tmp[0] === 'role') ROLE = decodeURIComponent(tmp[1])
      })
    if (TOKEN) {
      const fetchData = async (token) => {
        const data = await API({
          url: 'get_user_by_token',
          method: 'GET',
          headers: {
            'X-Authentication-Token': token,
          },
        })
        setApiData(data)

        if (data.meta.ok) {
          if (ROLE === 'researcher') {
            setCookie('access_token', TOKEN, {
              path: '/',
              secure: process.env.NODE_ENV === 'development' ? false : true, //http:false ,https:true
              httpOnly: false, //clientreadable:false ,//serverreadable:true
              // domain:'.allsubdomains.com',
              sameSite: 'strict',
            })
            setCookie('user_data', data.data.attributes.firstName, {
              path: '/',
              secure: process.env.NODE_ENV === 'development' ? false : true, //http:false ,https:true
              httpOnly: false, //clientreadable:false ,//serverreadable:true
              // domain:'.allsubdomains.com',
              sameSite: 'strict',
            })
            history.push('/researcher/dashboard')
          } else {
            setCookie(
              'user_data_consumer',
              {
                user: data.data.attributes.firstName,
                token: TOKEN,
              },
              {
                path: '/',
                secure: process.env.NODE_ENV === 'development' ? false : true, //http:false ,https:true
                httpOnly: false, //clientreadable:false ,//serverreadable:true
                // domain:'.allsubdomains.com',
                // sameSite: 'strict'
              }
            )

            history.push('/consumer/dashboard')
          }

          //role distinguish logic
        } else {
          setSnackbar({
            varient: 'error',
            message: data.message,
          })
        }
      }
      fetchData(TOKEN)
    }
  }, [])
  if (!apiData) {
    return null
  }

  return (
    <Box
      minHeight="calc(100vh - 64px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ padding: '40px 0', zIndex: 1 }}
      >
        <Grid item xs={7}>
          <Typography
            className={classes.heading}
            style={{ textAlign: 'center', color: '#0098d1' }}
          >
            {apiData.ok
              ? 'Your Email Has been Successfully Verified'
              : 'Your Token Has Expired, Please try again'}
          </Typography>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(snackbar.message)}
        onClose={() =>
          setSnackbar((prevValue) => ({
            ...prevValue,
            message: '',
          }))
        }
      >
        <CustomSnackBar
          onClose={() =>
            setSnackbar((prevValue) => ({
              ...prevValue,
              message: '',
            }))
          }
          variant={snackbar.varient}
          message={`${snackbar.message}`}
        />
      </Snackbar>
    </Box>
  )
}

Impersonate.propTypes = {}

export default memo(Impersonate)
