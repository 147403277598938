import axios from 'axios'
import React from 'react'

export const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  )
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value)
  }, [localStorageKey, value])
  return [value, setValue]
}

export const useStateWithSessionStorage = (sessionStorageKey) => {
  const [value, setValue] = React.useState(
    sessionStorage.getItem(sessionStorageKey) || ''
  )
  React.useEffect(() => {
    sessionStorage.setItem(sessionStorageKey, value)
  }, [sessionStorageKey, value])
  return [value, setValue]
}

export const isSafariBrowser = () => {
  const is_chrome = navigator.userAgent.indexOf('Chrome') > -1
  const is_safari = navigator.userAgent.indexOf('Safari') > -1
  if (is_safari) {
    if (is_chrome)
      // Chrome seems to have both Chrome and Safari userAgents
      return false
    else return true
  }
  return false
}

export const stringToColor = (string) => {
  let hash = 0
  let i
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    colour += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */
  return colour
}

export const API = ({
  url,
  apiVersion = 'api/v3/',
  method = 'GET',
  headers = {},
  apiUrl = process.env.REACT_APP_API_URL,
  body = null,
}) => {
  let options = {}
  if (method) {
    options = { ...options, method }
  }

  options = {
    ...options,
    // crossDomain:true,
    headers:
      process.env.REACT_APP_NODE_ENV === 'production'
        ? {
            'X-App-Id':
              'eb1d742e9a55f2ab293758be81d15b6cec1d24b663fe9d94309f0f140dea1142',
            'X-App-Secret':
              'd44a9cf4ee8f86988c633203a418b945bed7277db4a34372426fa65496dc6616',
            ...headers,
          }
        : {
            'X-App-Id':
              '2d1572caacc9d3a47ed4f34618d5a4f4adfd50239725192f32e6f454c2567979',
            'X-App-Secret':
              '7159ad44234a0a2d4c2a2747c069f635f8bf89930f37adc89225dc9a5dd97199',
            ...headers,
          },
  }

  if (body && method.toUpperCase() !== 'GET') {
    options = { ...options, body: body }
  }

  return new Promise((resolve, reject) => {
    let status
    fetch(`${apiUrl}${apiVersion}${url}`, options)
      .then((res) => {
        status = res.status
        return res.json()
      })

      .then(
        (data) => {
          resolve({ ...data, status })
        },
        (error) => {
          reject(error)
        }
      )
      .catch((error) => {
        console.error(
          'There has been a problem with your fetch operation:',
          error
        )
      })

    // try {

    //   const res = await fetch(`${API_URL}${url}`, options)
    //   const data = await res.json()
    //   // if (url === `${Config.API_URL}authors`) {
    //   //   reject(new Error('Unable to fetch authors data'));
    //   // }
    //   resolve(data)
    // } catch (error) {
    //   reject(error)
    // }
  })
}

export const Api = (token, apiVersion = 'api/v3/') => {
  const AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${apiVersion}`,
    timeout: 150 * 1000,
    headers:
      process.env.REACT_APP_NODE_ENV === 'production'
        ? {
            'X-App-Id':
              'eb1d742e9a55f2ab293758be81d15b6cec1d24b663fe9d94309f0f140dea1142',
            'X-App-Secret':
              'd44a9cf4ee8f86988c633203a418b945bed7277db4a34372426fa65496dc6616',
          }
        : {
            'X-App-Id':
              '2d1572caacc9d3a47ed4f34618d5a4f4adfd50239725192f32e6f454c2567979',
            'X-App-Secret':
              '7159ad44234a0a2d4c2a2747c069f635f8bf89930f37adc89225dc9a5dd97199',
          },
  })

  AxiosInstance.interceptors.request.use(
    (config) => {
      config.metadata = { startTime: new Date() }
      if (token) {
        config.headers['X-Authentication-Token'] = token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  AxiosInstance.interceptors.response.use(
    (response) => {
      response.config.metadata.endTime = new Date()
      response.duration =
        response.config.metadata.endTime - response.config.metadata.startTime
      return response
    },
    (error) => {
      error.config.metadata.endTime = new Date()
      error.duration =
        error.config.metadata.endTime - error.config.metadata.startTime
      return Promise.reject(error)
    }
  )

  return AxiosInstance
}

export const logApiError = (error) => {
  if (error.response) {
    console.log('Response Error', {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers,
    })
  } else if (error.request) {
    console.log('Request Error', error.request)
  } else {
    console.log('Config Error', error.message)
  }
}
